.pagination-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.page-button {
  color: var(--blue-font-color);
  padding:0px 10px;
  display: flex;
  align-items: center;
 }
 .page-button:disabled {
  color: var(--disable-font-color);
 }
 .page-button#first {
  padding-left: 0;
 }
 .total-items {
  padding-left: 10px;
 }
/* ================================================ */
/* レスポンシブ対応 */
/* ================================================ */
@media (max-width: 1024px) {
  
}
@media (max-width: 768px) {
  .page-button {
    padding: 0px 8px;
  }
}
@media (max-width: 480px) {
  
}