.modal-form#staff-setting-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
}

#staff-setting-form .staff-group-select {
  padding:10px 0;
}

#staff-setting-form #item-resend-mail {
  margin-top: 15px;
}

/* ============================== */
/* レスポンシブ対応 */
/* ============================== */
@media (max-width:1200px){
}
@media (max-width: 1024px) {
}
@media (max-width: 768px) {
}
@media (max-width: 480px) {
}
