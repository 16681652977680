.document-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index-modal-overlay);
}

.document-modal-container {
  background: #fff;
  padding: 20px;
  border: 1px solid #cccccc5e;
  border-radius: 10px;
  position: relative;
  box-shadow: 2px 7px 5px rgba(0, 0, 0, 0.1), 4px 14px 6px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: var(--z-index-modal-container);
}

.document-modal-top-contents {
  width: 100%;
  padding: 10px 0;
}

.document-modal-top-contents h2 {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.document-modal-center-contents {
  border: var(--line-color) solid 1px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.document-modal-scrollable-contents {
  background-color: #868686;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
}

.scale-indicator {
  position: absolute;
  top: 33px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.289);
  color: white;
  padding: 3px 7px;
  border-radius: 5px;
  font-size: 12px;
}

.document-modal-bottom-contents {
  width: 100%;
  padding: 10px 0 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.document-modal-bottom-contents .page-button-group,
.document-modal-bottom-contents .action-button-group {
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/*　ページボタン　*/
.document-modal-bottom-contents .page-button-group button {
  font-size: 18px;
}
.document-modal-bottom-contents .page-button-group p {
  font-size: 14px;
}

/*　アクションボタン　*/
.document-modal-bottom-contents .action-button-group img {
  width: 20px;
  height: 20px;
}
.document-modal-bottom-contents .action-button-group {
  justify-content: space-between;
  padding:0 10px;
  box-sizing: border-box;
}
.document-modal-bottom-contents .action-button-group .zoom-buttons {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.document-modal-bottom-contents .action-button-group .zoom-buttons button:first-child {
  padding-left: 0;
}

/* 完了ボタン */
.document-modal-bottom-contents .submit-buttons {
  justify-content: flex-end;
}
.document-modal-bottom-contents .submit-buttons .complete-button {
  font-size: 14px;
  color: var(--blue-font-color);
}

/* ============================== */
/* レスポンシブ対応 */
/* ============================== */
@media (max-width: 768px) {
  .document-modal-bottom-contents .action-button-group {
    justify-content: space-between;
  }
  .document-modal-bottom-contents .page-button-group {
    gap: 20px;
  }
  .document-modal-bottom-contents .action-button-group .zoom-buttons {
    gap: 20px;
  }
}
@media (max-width: 480px) {
  .document-modal-bottom-contents .action-button-group .zoom-buttons button {
    padding-left: 10px;
  }
  .document-modal-bottom-contents .page-button-group {
    gap: 15px;
  }
  .document-modal-bottom-contents .action-button-group .zoom-buttons {
    gap: 15px;
  }
}