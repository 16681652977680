/*code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* 共通定義とリセットCSS */
/* ================================================ */
/* カラー変数 */
/* ================================================ */
:root {
  --primary-color: #1687ff;

  --back-ground-color:#f5f5f5;
  --back-ground-color2:#edebeb;
  --content-back-ground-color:#ffffff;
  --bottom-sheet-back-ground-color: #000000a3;
  --pdf-viewer-back-ground-color: #606060;
  --hover-color: #1687ff30;

  --transparent-color: #00000000;
  --red-font-color: #eb6161;
  --green-font-color: #58b158;
  --blue-font-color: #007bff;
  --white-font-color: #ffffff;
  --selected-font-color: #555555;
  --default-font-color: #333333;
  --disable-font-color: #999999;
  --sub-font-color: #878787;
  --bottom-sheet-font-color: #ffffffbf;

  --line-color: #cccccc;
  --correct-color: #f7fff9;
  --incorrect-color: #fff4f5;
  
  /* カード */
  --card-back-ground-color: #f8f9fa;
  --card-border-color: #e9ecef;
  --card-sub-font-color: #6c757d;

  /* フォント変数 */
  --primary-font: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --secondary-font: 'Georgia', serif;

  /* z-index */
  --z-index-header: 1000;
  --z-index-modal-overlay: 2000;
  --z-index-modal-container: 2001;
  --z-index-modal-pdf: 2002;
  --z-index-bottom-sheet:3000;
  --z-index-loading:4000;
}

/* ================================================ */
/* スタイルのリセット */
/* ================================================ */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}