.bottom-sheet {
  position: fixed;
  bottom: -50px;
  left: 0;
  right: 0;
  background-color: var(--bottom-sheet-back-ground-color);
  color: var(--bottom-sheet-font-color);
  padding: 10px;
  text-align: center;
  transition: transform 0.6s ease-in-out, bottom 0.6s ease-in-out;
  transform: translateY(100%);
  z-index: var(--z-index-bottom-sheet);
  pointer-events: none;
}

.bottom-sheet.visible {
  bottom: 0;
  transform: translateY(0);
  pointer-events: auto;
}

.bottom-sheet-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottom-sheet button {
  background: none;
  border: none;
  color: var(--white-font-color);
  font-size: 12px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .bottom-sheet {
    padding: 10px 30px;
    bottom: -100px;
  }
}