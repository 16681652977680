.modal-overlay#select-company-modal{
  /* 2階層目のモーダルのため、背景を透明にする */
  background: transparent;
}

.select-company-modal .card-list {
  margin: 0;
  overflow-x: hidden;
}

@media (max-width: 1200px) {
  /* 2階層目のモーダルのため、シャドウを強めにする */
  .modal-overlay#select-company-modal .modal-container{
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.09),
                 4px 10px 8px rgba(0, 0, 0, 0.09),
                 0 -8px 12px rgba(0, 0, 0, 0.09),
                 8px 20px 12px rgba(0, 0, 0, 0.09);
  }
  .select-company-modal .card-list {
    padding: 15px 30px;
  }
}

@media (max-width: 480px) {
  .select-company-modal .card-list {
    padding: 10px;
  }
}