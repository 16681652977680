.modal-form#customer-form{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
}

.modal-form#customer-form .item-group,
.modal-form#customer-form .sp-item-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

/* デスクトップ */
.modal-form#customer-form .row-set-group{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

/* スマートフォン */
.modal-form#customer-form .sp-row-set-group{
  padding:20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px var(--line-color);
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
}

/* デスクトップ */
.modal-form#customer-form .row-set-group .row-set #item-name,
.modal-form#customer-form .row-set-group .row-set#item-name-header {
  width: 40%;
}
.modal-form#customer-form .row-set-group .row-set #item-mail,
.modal-form#customer-form .row-set-group .row-set#item-mail-header {
  width: 40%;
}
.modal-form#customer-form .row-set-group .row-set #item-resend-mail,
.modal-form#customer-form .row-set-group .row-set#item-resend-mail-header {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  width: 3%;
}
.modal-form#customer-form .row-set-group .row-set #item-delete-button,
.modal-form#customer-form .row-set-group .row-set#item-delete-button-header {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 5%;
}

/* スマートフォン */
.modal-form#customer-form .sp-row-set-group .row-set #item-name {
  width: 85%;
}
.modal-form#customer-form .sp-row-set-group .row-set #item-kana{
  width: 85%;
}
.modal-form#customer-form .sp-row-set-group .row-set #item-mail {
  width: 85%;
}
.modal-form#customer-form .sp-row-set-group .row-set #item-resend-mail {
  width: 10%;
}
.modal-form#customer-form .sp-row-set-group .row-set #item-delete-button {
  width: 5%;
}

.modal-form#customer-form .sp-row-set-group .row-set#item-resend-mail {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-top: 13px;
}
.modal-form#customer-form .sp-row-set-group .row-set#item-delete-button {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 5%;
}
.modal-form#customer-form .sp-row-set-group .row-set#item-resend-mail img {
  width: 30px;
}
.sp-item-group .add-button-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-top:10px;
}

@media (max-width: 480px) {
  .modal-form#customer-form .sp-row-set-group{
    padding:10px;
    gap: 5px;
  }
}