.login-container {
  align-items: center;
  background-image: url('../../../public/images/login-background.png');
  background-size: cover;
  background-position: right;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.login-form {
  width: 20%;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: var(--content-back-ground-color);
}
.visible-button-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.submit-button {
  width: 100%;
  padding: 10px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.column-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
}

/*=========================*/
/*レスポンシブ対応*/
/*=========================*/
@media (max-width: 1200px) {
  .login-form {
    width: 30%;
  }
}
@media (max-width: 1024px) {
  .login-form {
    width: 40%;
  }
}
@media (max-width: 768px) {
  .login-form {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .login-form {
    width: 70%;
  }
}