.invoice-preview {
  width: 210mm;
  padding: 20mm;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
}

.invoice-preview p,
.invoice-preview span{
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
}

/* ----------------------------- */
.invoice-super-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

/* ----------------------------- */
/* タイトル */
.invoice-super-header h1{
  padding-bottom:80px;
}

/* 発行日付と請求書番号 */
.invoice-super-header .date,
.invoice-super-header .invoiceNumber{
  font-size: 12px;
  text-align: right;
}

/* ----------------------------- */
/* ヘッダー */
.invoice-header {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  padding-bottom: 30px;
}

.invoice-header-left,
.invoice-header-right {
  flex: 1;
}
/* ----------------------------- */
/* 自社名 */
p.invoice-header-companyName{
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 10px;  
}
/* ----------------------------- */
/* インボイス登録番号 */
.invoice-header-businessNumber{
 padding-top:10px;
}
/* ----------------------------- */
/* 相手先会社名 */
.invoice-header-cutomerName-wrapper{
  border-bottom: 2px solid #000000;
}

p.invoice-header-cutomerName {
  font-size:18px;
  font-weight: 600;
}

/* 下記の通り、ご請求申し上げます。 */
.invoice-header-message {
  font-size: 12px;
  font-weight: lighter;
  padding-top:5px;
}

/* ----------------------------- */
/* 合計請求金額 */
.invoice-header-totalAmountWithTax-wrapper{
  border-bottom: 2px solid #000000;
  padding-top:30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}
.invoice-header-totalAmountWithTax-wrapper span{
  font-size:12px;
  padding-right: 20px;
  font-weight: lighter;
  padding:0;
  margin:0;
}
.invoice-header-totalAmountWithTax{
  padding-right:0;
  margin-right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  align-items: baseline;
}
.invoice-header-totalAmountWithTax-wrapper p{
  font-size:20px;
}

/* ----------------------------- */
.invoice-account-info {
  padding: 5px 0;
  margin-bottom: 10px;
}

.invoice-account-info-bankAccount,
.invoice-account-info-paymentDue{
  font-size: 12px;
  font-weight: 300;
}

.invoice-summary,
.invoice-total {
  padding: 10px;
  background-color: #ffffff;
  margin-bottom: 10px;
}

/* ----------------------------- */
.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.invoice-table th {
  background-color: #f4f4f4;
}

td.invoice-table-item-name{
  text-align: left;  
}

td.invoice-table-item-quantity,
td.invoice-table-item-unitPrice,
td.invoice-table-item-amount{
  text-align: right;
}
/* ----------------------------- */
.invoice-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 10px;
}

.invoice-summary,
.invoice-total{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.invoice-summary-totalAmount,
.invoice-summary-taxAmount,
.invoice-total-totalAmountWithTax{
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 gap:30px;
 align-items: baseline;
 width: 100%;
}
.invoice-note{
  width: 100%;
  min-height: 100px;
  border: 1px solid #ccc;
  margin-top:50px;  
}

