#notification {
  background-color: var(--back-ground-color);
  display: flex;
  flex:1;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
}

/* ------------------------------------------------ */
/* メインコンテンツ */
/* ------------------------------------------------ */
#notification h2 {
  min-height: 21px;
}

/* ------------------------------------------------ */
/* カード */
/* ------------------------------------------------ */
#notification .card-contents {
  position: relative;
}

#notification .unread-badge {
  position: absolute;
  top: 30px;
  right: 15px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--blue-font-color);
}

/* ------------------------------------------------ */
/* input */
/* ------------------------------------------------ */
#notification input#notification-date {
  background-color: transparent;
  cursor: auto;
  border: none;
  font-weight: 500;
}

/* ------------------------------------------------ */
/* レスポンシブ対応 */
/* ------------------------------------------------ */
@media (max-width: 1699px) {
  #notification .header-contents .hide-option {
    display: none;
  }
}
