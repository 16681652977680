.modal-form#job-setting-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
}

#job-setting-form .select-job-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding:0 0 20px 0;
}
#job-setting-form .job-setting-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* ボタングループ */
#job-setting-form .button-wrapper-group {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

#job-setting-form button.border-blue {
  margin-top:50px;
}

/* PDFViewerのラッパー */
#job-setting-form .pdf-view-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}


#job-setting-form .staff-group-select{
  padding: 10px 0;
}

/* ------------------------------------------------ */
/* テンプレートボタン周辺 */
/* ------------------------------------------------ */
#job-setting-form .card#e-signature-card {
  width: 95%;
  margin: 0;
}

#job-setting-form .document-info {
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
}

#job-setting-form .document-buttons {
  display: flex;
  gap: 10px;
}

#job-setting-form .document-buttons button {
  padding: 5px;
  background: none;
  border: none;
  cursor: pointer;
}


#job-setting-form .vimeo-player-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 170px;
  padding-bottom: 20px;
}

@media (max-width:1200px){
#job-setting-form .vimeo-player-wrapper{
    padding-bottom: 20px;
    width: 75%;
  }
}

@media (max-width: 768px) {
#job-setting-form .vimeo-player-wrapper {
    width: 100%;
    height: 260px;
  }
}