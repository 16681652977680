.modal-form#customer-payment-form{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
}

/* ------------------------------------------------ */
/* ボタンコンテンツ */
/* ------------------------------------------------ */
.modal-form#customer-payment-form .button-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.modal-form#customer-payment-form .add-new-item-button {
  align-items: center;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
}

.modal-form#customer-payment-form .add-new-item-button p {
  color: var(--blue-font-color);
}

.modal-form#customer-payment-form .add-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-top:10px;
}
/* ------------------------------------------------ */
/* アイテム */
/* ------------------------------------------------ */
.modal-form#customer-payment-form .item-group,
.modal-form#customer-payment-form .sp-item-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

/* ------------------------------------------------ */
/* アイテム */
/* ------------------------------------------------ */
/* デスクトップ */
.modal-form#customer-payment-form .row-set-group{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.modal-form#customer-payment-form .row-set-group .row-set #item-name,
.modal-form#customer-payment-form .row-set-group .row-set#item-name-header {
  width: 30%;
}

.modal-form#customer-payment-form .row-set-group .row-set #item-quantity,
.modal-form#customer-payment-form .row-set-group .row-set#item-quantity-header {
  width: 10%;
}

.modal-form#customer-payment-form .row-set-group .row-set #item-price,
.modal-form#customer-payment-form .row-set-group .row-set#item-price-header {
  width: 25%;
}

.modal-form#customer-payment-form .row-set-group .row-set #item-amount,
.modal-form#customer-payment-form .row-set-group .row-set#item-amount-header {
  width: 25%;
}

.modal-form#customer-payment-form .row-set-group .row-set #item-delete-button,
.modal-form#customer-payment-form .row-set-group .row-set#item-delete-button-header {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 10%;
}

/* スマートフォン */
.modal-form#customer-payment-form .sp-row-set-group{
  padding:20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px var(--line-color);
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
}

.modal-form#customer-payment-form .sp-row-set-group .row-set #item-name {
  width: 100%;
}

.modal-form#customer-payment-form .sp-row-set-group .row-set #item-quantity{
  width: 20%;
}

.modal-form#customer-payment-form .sp-row-set-group .row-set #item-price {
  width: 40%;
}

.modal-form#customer-payment-form .sp-row-set-group .row-set #item-amount {
  width: 40%;
}

.modal-form#customer-payment-form .sp-row-set-group .row-set #item-delete-button{
  width: 5%;
}

.modal-form#customer-payment-form .sp-row-set-group .row-set#item-delete-button {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-top: 13px;
}
.modal-form#customer-payment-form .sp-row-set-group .row-set#item-delete-button {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 5%;
}
.modal-form#customer-payment-form .sp-row-set-group .row-set#item-delete-button img {
  width: 30px;
}

/* ------------------------------------------------ */
/* レスポンシブ対応 */
/* ------------------------------------------------ */
@media (max-width: 480px) {
  .modal-form#customer-payment-form .sp-row-set-group{
    padding:10px;
    gap: 5px;
  }
}