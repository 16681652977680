/* ================================================ */
/* グローバルスタイル */
/* ================================================ */
* {
  user-select: none;
  -webkit-user-select: none;
}

html,
body {
  height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--back-ground-color);
  color: var(--default-font-color);
  font-size: 14px;
  font-family: var(--primary-font);
  line-height: 1;
  margin: 0;
  max-width: 100vw;/*横スクロール制御*/
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  color: var(--default-font-color);
  font-family: var(--primary-font);
  font-weight: 400;
}

ul {
  list-style-type: none;
}

/* ================================================ */
/* ヘッダー、フッター、メインコンテンツの基本的なスタイリング */
/* ================================================ */
header,
footer,
main {
  padding: 0;
}

footer {
  margin-top: auto;
}

/* ================================================ */
/* メインコンテンツ */
/* ================================================ */
header {
  height: 65px;
}
.app-container {
  background-color: var(--back-ground-color);
  height: calc(100dvh - 65px);
  margin-top: 65px;
  width: 100vw;
  overflow: hidden;
  position: relative;
}
.full-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.menu-container {
  background-color: #e7e7e7;
  min-width: 180px;
}
.content-container {
  background-color: var(--content-back-ground-color);
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
}

/* ================================================ */
/* Link */
/* ================================================ */
a {
  color: var(--blue-font-color);
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:hover {
  text-decoration: none;
}

/* ================================================ */
/* Button */
/* ================================================ */
button.transparent {
  color: var(--transparent-color);
}
button.blue {
  cursor: pointer;
  color: var(--blue-font-color);
}
button.border-blue {
  cursor: pointer;
  color: var(--blue-font-color);
  border: 1px solid var(--blue-font-color);
  padding: 10px;
}
button.red {
  color: var(--red-font-color);
}
button.no-right-space {
  padding-right:0;
}
button.no-left-space {
  padding-left:0;
}
button {
  background-color: var(--secondary-color);
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--default-font-color);
  cursor: pointer;
  font-family: var(--primary-font);
  font-size: 14px;
  /* padding: 8px 16px; */
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
button:focus {
  outline: none;
}
button:disabled {
  color: var(--disable-font-color);
  cursor: auto;
}

/* ================================================ */
/* Select */
/* ================================================ */
select { 
  background-color: var(--content-back-ground-color);
  border: 1px solid var(--line-color);
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--default-font-color);
  cursor: pointer;
  font-family: var(--primary-font);
  font-size: 14px;
  height: 45px;
  padding: 8px;
  transition: all 0.3s ease;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}
select:focus {
  background-color: var(--white-color);
  border-color: var(--primary-color);
  outline: none;
}
select:disabled {
  background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
  cursor: auto;
}
/* ================================================ */
/* InputBox */
/* ================================================ */
input {
  background-color: var(--content-back-ground-color);
  border: 1px solid var(--line-color);
  border-radius: 4px;
  color: var(--default-font-color);
  font-family: var(--primary-font);
  padding: 8px;
  height: 45px;
  box-sizing: border-box;
}

input:focus {
  background-color: var(--content-back-ground-color);
  border-color: var(--primary-color);
  outline: none;
}

input:disabled {
  background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
  cursor: auto;
}

input::placeholder {
  color: #878787;
}

input[type="date"] {
  -webkit-appearance: none; /* Safari用 */
  -moz-appearance: none;    /* Firefox用 */
  appearance: none;         /* 共通 */
}

/* ================================================ */
/* TextArea */
/* ================================================ */
textarea {
  background-color: var(--content-back-ground-color);
  border: 1px solid var(--line-color);
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
  color: var(--default-font-color);
  font-family: var(--primary-font);
  font-size: 14px;
  padding: 8px;
}

textarea:focus {
  background-color: var(--content-back-ground-color);
  border-color: var(--primary-color);
  outline: none;
}

textarea:disabled {
  background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
  cursor: auto;
}

/* ================================================ */
/* Label */
/* ================================================ */


/* ================================================ */
/* CheckBox */
/* ================================================ */
input[type="checkbox"] {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

/* ================================================ */
/* Radio */
/* ================================================ */
input[type="radio"] {
  transform: scale(1.5);
  cursor: pointer;
}
input[type="radio"]:hover {
  accent-color: inherit;
}

/* ================================================ */
/* Table */
/* ================================================ */
table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}
table thead {
  border-bottom: 1px solid var(--line-color);
}
table tr{
  border-bottom: 0.5px solid var(--line-color);
}
table th,
table td {
  border: none;
  padding: auto;
  width: auto;
}
table th {
  background-color: transparent;
}

/* ================================================ */
/* ScrollBar */
/* ================================================ */
::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(119, 119, 119, 0.5);
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

/* ================================================ */
/* その他 */
/* ================================================ */
button, input, textarea, label, tr, th, td, div {
  /* タップしたときにハイライトされないようにする */
  -webkit-tap-highlight-color: transparent;
}

/* ================================================ */
/* レスポンシブ対応 */
/* ================================================ */

@media (max-width: 1200px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 1024px) {
  body,button {
    font-size: 13px;
  }
  input[type="radio"] {
    transform: scale(1.3);
  }
}

@media (max-width: 768px) {
  header {
    height: 50px;
  }
  .app-container{
    /* height: calc(100vh - 50px); */
    height: calc(100dvh - 50px);
    margin-top: 50px;
  }
  .content-container {
    width: 100%;
  }
  .menu-container {
    display: none;
  }
  input[type="radio"] {
    transform: scale(1.1);
  } 
  table tr{
    border-bottom: 0.8px solid var(--line-color);
  }
}
