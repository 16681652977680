/* ========================== */
/* 共通カラー */
/* ========================== */
.lc-modal-overlay {
  --select-background-color: #d9ebff;
}

/* ========================== */
/* オーバーレイ */
/* ========================== */
.lc-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index-modal-overlay);
  line-height: 1.4;
}

/* ========================== */
/* モーダルコンテンツ */
/* ========================== */
.lc-modal-overlay .lc-modal-container {
  background: #fff;
  padding: 20px 50px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: var(--z-index-modal-container);
}

/* ========================== */
/* モーダル上部コンテンツ */
/* ========================== */
.lc-modal-overlay .lc-modal-top-contents {
  height: 5vh;
  width: 100%;
}
.lc-modal-overlay .lc-modal-top-contents h2{
  text-align: left;
}

/* ========================== */
/* モーダル中央コンテンツ */
/* ========================== */
.lc-modal-overlay .lc-modal-center-contents{
  box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
  height: 70vh;
  width: 80vw;
  overflow-y: auto;
}

/* ========================== */
/* 問題コンテンツ */
/* ========================== */
.lc-modal-overlay .lc-question-item{
  padding:20px 40px;
}
.lc-question-item h4{
  padding-bottom: 10px;
}
/* 選択肢リスト */
.lc-modal-overlay .lc-answer-list{
  display: flex;
  padding: 20px 0;
  flex-direction: column;
  gap: 10px;
}

/* 選択肢とマークテキスト */
.lc-modal-overlay .lc-answer-item-container{
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}
/* 選択肢 */
.lc-modal-overlay .lc-answer-item{
  align-items: center;
  background-color: var(--card-back-ground-color);
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  width: 100%;
  padding:10px;
  min-height: 30px;
}
/*　選択肢背景色　*/
.lc-modal-overlay .lc-answer-item.selecting {
  background-color: var(--select-background-color);
}
.lc-modal-overlay .lc-answer-item.answer {
  background-color: var(--incorrect-color);
}

/* マークテキストエリア */
.lc-modal-overlay .lc-answer-mark-area{
  text-align: left;
  width: 5%;
}
/* マークテキスト */
.lc-modal-overlay .select-mark,
.lc-modal-overlay .correct-mark,
.lc-modal-overlay .incorrect-mark{
  color:var(--blue-font-color);
  text-align: left;
}
.lc-modal-overlay .select-mark img,
.lc-modal-overlay .correct-mark,
.lc-modal-overlay .incorrect-mark,
.lc-modal-overlay .answer-mark,
.lc-modal-overlay .spacer {
  width: 20px;
}

.lc-modal-overlay .correct-mark,
.lc-modal-overlay .incorrect-mark {
  font-size: 30px;
}
.lc-modal-overlay .answer-mark {
  color:var(--red-font-color);
  text-align: center;
  font-size: 20px;
}

/* ========================== */
/* モーダル下部コンテンツ */
/* ========================== */
.lc-modal-overlay .lc-modal-bottom-contents{
  display: flex;
  flex-direction: column;
  height: 5vh;
  justify-content: flex-end;
  width: 100%;
}
.lc-modal-overlay .lc-modal-bottom-contents .button-group{
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: flex-end;
  text-align: right;
}

/* ============================== */
/* レスポンシブ対応 */
/* ============================== */
@media (max-width: 768px) {
  .lc-modal-overlay .lc-modal-container {
    padding: 10px;    
    height: 85vh;
    width: 90vw;
  }

  /* モーダルコンテンツ */
  .lc-modal-overlay .lc-modal-top-contents {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0 0 0;
    margin: 0;
  }
  .lc-modal-overlay .lc-modal-center-contents{
    height: 85vh;
    width: 95%;
  }
  .lc-modal-overlay .lc-modal-bottom-contents{
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
  }

  /* 問題コンテンツ */
  .lc-modal-overlay .lc-question-item{
    padding: 20px;
  }
  .lc-modal-overlay .lc-answer-list{
    padding: 20px 0;
  }
  .lc-modal-overlay .lc-answer-item{
    width: 83%;
  }
  .lc-modal-overlay .select-mark img{
    width: 15px;
  }
}

@media (max-width: 480px) {
  
}
