.modal-form#admin-master-form{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
}

.modal-form#admin-master-form .company-select {
  padding:10px 0;
}

.modal-form#admin-master-form #item-resend-mail {
  margin-top: 15px;
}

/* ============================== */
/* レスポンシブ対応 */
/* ============================== */
@media (max-width:1200px){
}
@media (max-width: 1024px) {
}
@media (max-width: 768px) {
}
@media (max-width: 480px) {
}
