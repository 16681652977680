/* ------------------------------------------------ */
/* 一覧全体 */
/* ------------------------------------------------ */
.view-contents {
  box-sizing: border-box;
  height: 100%;
  padding: 20px 30px;
  width: 100%;
}

/* ------------------------------------------------ */
/* メインコンテンツ */
/* ------------------------------------------------ */
.view-contents .main-contents {
  background-color: var(--content-back-ground-color);
  border-radius: 10px;
  display: flex;
  flex:1;
  flex-direction: column;
  height: auto;
  padding: 30px;
}

.view-contents h2 {
  margin-bottom: 10px;
}

/* ------------------------------------------------ */
/* ヘッダーコンテンツ */
/* ------------------------------------------------ */
.view-contents .header-contents {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  min-height: 55px;
}

/* ------------------------------------------------ */
/* ミドルコンテンツ */
/* ------------------------------------------------ */
.middle-contents {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
}

.middle-contents .left-contents {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  height: 37px;
}

/* ------------------------------------------------ */
/* 検索バー */
/* ------------------------------------------------ */
.search-bar {
  display: block;
  margin: 0;
  width: 70%;
}

.search-bar input[type="text"]::placeholder{
  font-size: 16px;
}

.search-bar input[type="text"] {
  width: 100%;
}

/* ------------------------------------------------ */
/* ボタンコンテンツ */
/* ------------------------------------------------ */
.header-contents .button-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  margin-left: 20px;
  margin-right: 10px;
}

.middle-contents .button-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.add-new-item-button,
.output-pdf-button {
  align-items: center;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
}

.add-new-item-button p,
.output-pdf-button p {
  color: var(--blue-font-color);
}

/* ------------------------------------------------ */
/* サブコンテンツ */
/* ------------------------------------------------ */
.sub-contents {
  background-color: var(--content-back-ground-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex:1;
  height: auto;
  padding: 30px 30px 15px;
  max-width: 50%;
}

/* ------------------------------------------------ */
/* スクロールフォーム */
/* ------------------------------------------------ */
.scroll-form {
  margin-top: 50px;
  overflow-y: auto;
  height: 100%;
}

.bottom-button {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-end;
  margin-top: 20px;
}



/* ------------------------------------------------ */
/* フォーム項目オプション */
/* ------------------------------------------------ */
.row-group {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
}

.width50 {
  width: 45%;
}

.width100{
 width: 95%;
}

.spacer30 {
  padding-top: 30px;
}

.separate-title {
  font-weight:600;
  padding-bottom: 10px;
  line-height: 1.4;
}

.separate-line {
  border-top: var(--line-color) solid 1px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.toggle-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#item-resend-mail {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 0;
}

#item-resend-mail button {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap:5px;
  justify-content: flex-start;
}

/* InputField用 */
.column-set {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}

.row-set {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.input-group{
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}

.input-group input{
  width: 100%;
}

/* ------------------------------------------------ */
/* エラーメッセージ */
/* ------------------------------------------------ */
.error-message-container {
  position: relative;
}

.error-message-container.has-error {
  margin-bottom: 20px;
}

.error-message {
  color: red;
  font-size: 12px;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 5px;
}

/* 複数のエラーメッセージ */
.item-error-messages {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: red;
  font-size: 14px;
  padding:10px 0;
}

.item-error-messages .item-error-message {
  color: red;
  font-size: 12px;   
  margin-top: 5px;
}

/* ------------------------------------------------ */
/* カードリスト */
/* ------------------------------------------------ */
.card-list {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 5px 0 0;
  margin-top: 20px;
}

.card-list#modal-card-list {
  margin: 0;
  padding: 10px;
}

.card {
  background-color: var(--content-back-ground-color);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom:10px;
}

.card-contents {
  background-color: var(--card-back-ground-color);
  padding: 15px;
  border-bottom: 1px solid var(--card-border-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card-contents-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.card-contents h3 {
  margin: 0;
}

.card-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.card-contents .sub-text {
  font-size: 14px;
  color: var(--card-sub-font-color);
}

.card-contents .send-to {
  font-size: 14px;
  color: var(--card-sub-font-color);
  align-self: flex-start;
}

.card-footer {
  display: flex;
  justify-content: flex-end;
}

.card-footer button {
  margin-left: 10px;
}

.no-data {
  grid-column: 1 / -1;
  text-align: center;
  padding: 20px;
  background-color: var(--card-back-ground-color);
  border-radius: 8px;
}

/* ============================== */
/* モーダルフォーム */
/* ============================== */
.modal-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
}
/* ラベル */
.modal-form label {
  display: flex;
  /* flex-direction: column; */
  gap: 5px;
}
/* inputField */
.modal-form #if-name,
.modal-form #if-mail,
.modal-form #if-dates,
.modal-form #password,
.modal-form #if-price {
 width: 50%;
}

.modal-form #if-postalcode {
  width: 30%;
}

.modal-form #if-plan,
.modal-form #if-sex {
  width: 25%;
}

.modal-form #if-tax {
  width: 20%;
}

.modal-form #if-address,
.modal-form #if-note {
  width: 75%;
}

/* 入力フォーム */
.modal-form input {
  padding: 8px;
  border: 1px solid var(--line-color);
  border-radius: 4px;
}
/* テキストエリア */
.modal-form textarea {
  padding: 8px;
  border: 1px solid var(--line-color);
  border-radius: 4px;
  height:100px;
}
/* アコーディオンフォーム */
.modal-accordion-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* フォームのアクションボタンスタイル */
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
}
/* ボタン */
.form-actions button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.form-actions button[type="button"] {
  background-color: #f5f5f5;
  color: #333;
}
.form-actions button[type="submit"] {
  background-color: #007bff;
  color: white;
}

/* ============================== */
/* 郵便番号入力 */
/* ============================== */
.postalcode-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.postalcode-row .input-group {
  flex: 1;
}

.postalcode-row button {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

/* ============================== */
/* レスポンシブ対応 */
/* ============================== */
@media (max-width: 1599px) {
  .middle-contents {
    align-items: flex-start;
    flex-direction: column;
  }
  .sub-contents {
    max-width: 100%;
  }
}

@media (max-width: 1200px) {
  /* 検索欄 */
  .search-container {
    align-items: flex-start;
    flex-direction: column;
    gap: 0;
    justify-content: center;
    margin-bottom: 0;
  }
  .search-bar {
    width: 100%;
  }

  .sub-contents {
    background-color: transparent;
  }

  /* スクロールフォーム */ 
  .scroll-form {
    margin-top: 0;
  }

  /* ボタン */
  .bottom-button {
    display: none;
  }

  /* カラム */
  .view-list-container thead th,
  .view-list-container tbody td {
    padding: 5px;
  }
  /* inputField */
  .modal-form #if-name,
  .modal-form #if-mail,
  .modal-form #if-dates,
  .modal-form #password,
  .modal-form #if-price {
    width: 75%;
  }
  
  /* 一覧スクロール対応 */
  .modal-container .sub-contents {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
  }
  .modal-container .sub-contents .header-contents,
  .modal-container .sub-contents .middle-contents {
    flex-shrink: 0;
  }
  .modal-container .sub-contents .card-list {
    flex-grow: 1;
    overflow-y: auto;
  }
}

@media (max-width: 1000px) {
  .search-bar input[type="text"]::placeholder{
    font-size: 15px;
  }  
}

@media (max-width: 768px) {
  /* ページ全体 */
  .view-contents {
    padding: 20px;
  }
  .view-contents .header-contents {
    padding: 0;
    gap: 0;
  }
  .view-contents .main-contents {
    padding: 10px;
  }
  .view-contents .middle-contents {
    gap: 5px;
  }
  
  /* 検索欄 */
  .search-bar input {
    width: 100%;
  }

  /* 一覧 */
  .view-list {
    padding-top: 10px;
  }
  .view-list-container {
    margin-top: 15px;
  }
  .view-list-container tbody {
    max-height: 60vh;
  }

  /* カラム */
  .view-list-container thead th,
  .view-list-container tbody td {
    padding: 5px;
  }
  .movie-icon img {
    width: 15px;
    height: 15px;
  }
  
  /* モーダル */
  .modal-form {
    padding: 20px;
  }

  /* inputField */
  .modal-form #if-name,
  .modal-form #if-mail,
  .modal-form #if-dates,
  .modal-form #password,
  .modal-form #if-price {
    width: 100%;
  }
  
  .modal-form #if-postalcode{
    width: 60%;
  }

  .modal-form #if-plan,
  .modal-form #if-sex {
    width: 40%;
  }

  .modal-form #if-tax {
    width: 30%;
  }

  .modal-form #if-address,
  .modal-form #if-note {
    width: 100%;
  }

  /* その他 */
  .separate-line {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .add-new-item-button {
    justify-content: flex-end;
  }
}

@media (max-width: 480px) {  
  /* フィルターと追加ボタン */
  .filter-and-add-button {
    gap: 0px;
  }
  /* サブコンテンツ */
  .sub-contents {
    padding: 10px;
  }
}