#my-page {
  background-color: var(--back-ground-color);
  box-sizing: border-box;
  display: flex;
  flex:1;
  flex-direction: row;
  height: 100%;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

/* ------------------------------------------------ */
/* カード */
/* ------------------------------------------------ */
#my-page-card-contents {
  cursor: pointer;
}

/* ------------------------------------------------ */
/* その他 */
/* ------------------------------------------------ */
#my-page .sub-text-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

/* ------------------------------------------------ */
/* レスポンシブ対応 */
/* ------------------------------------------------ */
@media (max-width: 1699px) {
  #my-page .hide-option {
    display: none;
  }

  #my-page .middle-contents {
    flex-direction: column;
    align-items: flex-start;
    gap:5px
  }
}

@media (max-width: 1200px) {
  #my-page .middle-contents {
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
  
  #my-page .scroll-form {
    margin-top: 0;
  }
}

@media (max-width: 950px) {
  #my-page .middle-contents {
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
  }
}

@media (max-width: 768px) {
  /* ------------------------------------------------ */
  /* 検索バー */
  /* ------------------------------------------------ */
  #my-page .search-bar {
    width: 100%;
  }
}

@media (max-width: 480px) {
  #my-page .sub-text-container {
    flex-direction: column;
  }
}