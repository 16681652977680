.modal-form#notification-setting-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
}

.modal-form#notification-setting-form .group-select,
.modal-form#notification-setting-form .staff-select {
  padding:10px 0;
}

.modal-form#notification-setting-form .group-select-view,
.modal-form#notification-setting-form .staff-select-view {
  background-color: var(--card-back-ground-color);
  border-radius: 5px;
  padding: 10px;
}

.modal-form#notification-setting-form .group-select-count,
.modal-form#notification-setting-form .staff-select-count {
  background-color: var(--card-back-ground-color);
  border-radius: 15px;
  color: var(--default-font-color);
  font-size: 12px;
  margin-left: 5px;
  padding: 2px 8px;
  vertical-align: middle;
}