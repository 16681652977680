.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index-modal-overlay);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal-overlay.visible {
  opacity: 1;
  visibility: visible;
}

.modal-overlay.closing {
  opacity: 0;
}

.modal-container {
  background: #fff;
  padding: 20px 50px;
  border: 1px solid #cccccc5e;
  border-radius: 10px;
  position: relative;
  box-shadow: 2px 7px 5px rgba(0, 0, 0, 0.1), 4px 14px 6px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: var(--z-index-modal-container);
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.modal-container.visible {
  opacity: 1;
  transform: scale(1);
}

.modal-container.closing {
  opacity: 0;
  transform: scale(0.9);
}

.modal-top-contents{
  width: 100%;
  padding: 10px 0;
}

.modal-top-contents h2{
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-center-contents{
  box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-bottom-contents{
  width: 100%;
  padding: 10px 0 0;
}

.modal-bottom-contents .button-group{
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: flex-end;
  text-align: right;
  padding-top: 10px;
}

/* ============================== */
/* レスポンシブ対応 */
/* ============================== */
@media (max-width: 768px) {
  .modal-overlay .modal-container {
    padding: 10px;    
  }
  
  /* -------------------------- */
  /* モーダルコンテンツ */
  /* -------------------------- */
  .modal-overlay .modal-top-contents {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .modal-overlay .modal-center-contents{
    min-height: 30vh;
  }
  .modal-overlay .modal-bottom-contents{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}