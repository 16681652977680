.output-button {
  position: relative;
  display: inline-block;
}

.output-button-popup {
  position: absolute;
  top: 100%;
  left: -23px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 75px;
  gap: 20px;
}

.output-button .output-select-button {
  align-items: center;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
}

.output-button .output-select-button p {
  color: var(--blue-font-color);
}