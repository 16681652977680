#job-master {
  background-color: var(--back-ground-color);
  display: flex;
  flex:1;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

/* ------------------------------------------------ */
/* カード */
/* ------------------------------------------------ */
#job-setting-card-contents {
  cursor: pointer;
}

/* ------------------------------------------------ */
/* レスポンシブ対応 */
/* ------------------------------------------------ */
@media (max-width: 1699px) {
  #job-master .hide-option {
    display: none;
  }
}