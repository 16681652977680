.create-account-container {
  align-items: center;
  background-image: url('../../../public/images/login-background.png');
  background-size: cover;
  background-position: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.create-account-form {
  background-color: var(--content-back-ground-color);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 40px;
  width: 300px;
}

.create-account-container .password-criteria-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.create-account-container .visible-button-container {
  margin:0;
}

.create-account-container .submit-button:disabled {
  color: #fff;
  background-color: #ccc;
  cursor: not-allowed;
}

.password-criteria {
  margin-top: 10px;
}

.password-criteria p {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.password-criteria .check-icon-container {
  min-width: 18px;
  height: 18px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.password-criteria .check-icon {
  width: 18px;
  height: 18px;
  stroke: #32b948;
  stroke-width: 1.6;
  stroke-linecap: square;
  stroke-linejoin: miter;
  fill: none;
  color: #32b948;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.password-criteria .check-icon.visible {
  opacity: 1;
}

/*=========================*/
/*レスポンシブ対応*/
/*=========================*/
@media (max-width: 1200px) {
  .create-account-form {
    width: 30%;
  }
}
@media (max-width: 1024px) {
  .create-account-form {
    width: 40%;
  }
}
@media (max-width: 768px) {
  .create-account-form {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .create-account-form {
    width: 70%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}