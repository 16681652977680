#staff-group-setting {
  background-color: var(--back-ground-color);
  display: flex;
  flex:1;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

/* ------------------------------------------------ */
/* カード */
/* ------------------------------------------------ */
#staff-group-setting-card-contents {
  cursor: pointer;
}


/* ------------------------------------------------ */
/* レスポンシブ対応 */
/* ------------------------------------------------ */
@media (max-width: 1599px) {
  #staff-group-setting .hide-option {
    display: none;
  }
}