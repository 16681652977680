.pdf-viewer-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index-modal-pdf);
}

.pdf-viewer-modal-container {
  background-color: white;
  border-radius: 8px;
  width: 80vw;
  height: 85vh;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1200px;
}

.pdf-viewer-modal-header {
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pdf-viewer-modal-header h2 {
  margin: 0;
  font-size: 1.2rem;
}

.pdf-viewer-modal-content {
  flex: 1;
  overflow: hidden;
  padding: 20px;
}

.pdf-viewer-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.pdf-viewer-modal-footer {
  padding: 15px 20px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.confirm-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.confirm-checkbox input[type="checkbox"] {
  width: 18px;
  height: 18px;
}

.confirm-button {
  color: var(--blue-font-color);
}

@media (max-width: 768px) {
  .pdf-viewer-modal-container {
    width: 95vw;
    height: 80vh;
    margin: 10px;
  }

  .pdf-viewer-modal-content {
    padding: 10px;
  }
}