#account-setting {
  background-color: var(--back-ground-color);
  display: flex;
  flex:1;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

/* ------------------------------------------------ */
/* ミドルコンテンツ */
/* ------------------------------------------------ */
#account-setting .middle-contents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* ------------------------------------------------ */
/* フォーム */
/* ------------------------------------------------ */
#account-setting .account-info-container,
#account-setting .plan-info-container,
#account-setting .reset-result-container,
#account-setting .billing-info-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

#account-setting .plan-info-title {
  background-color: var(--card-back-ground-color);
  border-radius: 10px;
  padding: 15px;
  border-bottom: 1px solid var(--card-border-color);
  display: flex;
  flex-direction: column;
  gap:10px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 200px;
}

#account-setting .plan-info-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 200px;
}

#account-setting .plan-info-item {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}

#account-setting .sub-text {
  font-size: 14px;
  color: var(--card-sub-font-color);
}

/* ------------------------------------------------ */
/* ボタン */
/* ------------------------------------------------ */
#account-setting .reset-password-button{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding-top: 20px;
}

#account-setting .reset-result-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

#account-setting .billing-bottom-button {
  margin-top: 20px;
}

/* ------------------------------------------------ */
/* その他 */
/* ------------------------------------------------ */
#account-setting .spacer {
  height: 10px;
}

#account-setting #if-name,
#account-setting #if-address,
#account-setting #if-note {
 width: 30%;
}

#account-setting #if-postalcode {
  width: 15%;
}

#account-setting #if-postalcode .postalcode-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

#account-setting #if-postalcode .input-group {
  flex: 1;
}

#account-setting #if-postalcode button {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

#account-setting #if-note textarea {
  height: 100px;
}

/* ------------------------------------------------ */
/* レスポンシブ対応 */
/* ------------------------------------------------ */
@media (max-width: 1600px) {
  #account-setting .middle-contents {
    align-items: flex-start;
    flex-direction: column;
    gap: 0;
  }

  #account-setting #if-name,
  #account-setting #if-address,
  #account-setting #if-note {
  width: 40%;
  }
  #account-setting #if-postalcode {
    width: 30%;
  }
}

@media (max-width: 1200px) {
  #account-setting #if-name,
  #account-setting #if-address,
  #account-setting #if-note {
    width: 50%;
  }
}

@media (max-width: 768px) {
  #account-setting .main-contents {
    padding: 30px;
  }
  
  #account-setting #if-name,
  #account-setting #if-address,
  #account-setting #if-note {
    width: 100%;
  }

  #account-setting #if-postalcode {
    width: 50%;
  }
}