.toast-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
}

.toast-overlay.fadeOut {
  animation: fadeOut 0.3s ease-out forwards;
}

.toast-container {
  background-color: var(--bottom-sheet-back-ground-color);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  animation: scaleIn 0.3s ease-out;
}

.toast-overlay.fadeOut .toast-container {
  animation: scaleOut 0.3s ease-out forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes scaleIn {
  from { transform: scale(0.8); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

@keyframes scaleOut {
  from { transform: scale(1); opacity: 1; }
  to { transform: scale(0.8); opacity: 0; }
}

.paper-plane {
  margin-bottom: 15px;
}

.toast-container p {
  color: white;
  text-align: center;
  margin: 0;
}