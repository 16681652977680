.sorting-button {
  align-items: center;
  display: flex;
  min-width: 150px;
  position: relative;
}

.sorting-button input[type="radio"] {
  accent-color: var(--blue-font-color);
  margin: 0 3px 0 5px;
}

.sorting-button .sorting-button-label-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.sorting-button .sorting-button-label {
  display: block;
}

.sorting-button label.sorting-button {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.sorting-button label.disabled p {
  color: var(--disable-font-color);
}

.sorting-button .button-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.sorting-button-popup {
  position: absolute;
  top: 85%;
  left: 0;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  width: 100%;
}

.sorting-button input[type="radio"] {
  margin-right: 5px;
}

.sorting-button.disabled {
  color: #ccc;
}

/* ============================== */
/* レスポンシブ対応 */
/* ============================== */
@media (max-width: 768px) {
  .sorting-button .sorting-button-label {
    display: none;
  }
}

@media (max-width: 480px) {
  .sorting-button {
    gap: 15px;
  }
}