.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: var(--z-index-loading);
}
.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid transparent;
  border-top-color: var(--primary-color);
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  right: 20px;
  animation: spin 1.5s linear infinite, gradient 3s ease-in-out infinite;
}

@keyframes gradient {
  0% {
    border-top-color: var(--back-ground-color);
  }
  50% {
    border-top-color: var(--primary-color);
  }
  100% {
    border-top-color: var(--back-ground-color);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}