#terms-of-service-container {
  background-color: var(--back-ground-color);
  display: flex;
  flex:1;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

/* ------------------------------------------------ */
/* スクロールフォーム */
/* ------------------------------------------------ */
#terms-of-service-container .terms-of-service-form {
  line-height: 1.6;
  font-size: 14px;
  color: #333;
  width: 60%;
}

#terms-of-service-container .terms-of-service-form h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

#terms-of-service-container .terms-of-service-form p {
  margin-bottom: 10px;
}

#terms-of-service-container .terms-of-service-form .section-title {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

/* ------------------------------------------------ */
/* レスポンシブ対応 */
/* ------------------------------------------------ */
@media (max-width: 1200px) {
  #terms-of-service-container .terms-of-service-form {
    width: 80%;
  }
}

@media (max-width: 768px) {
  #terms-of-service-container .main-contents {
    padding: 30px;
  }
  
  #terms-of-service-container .terms-of-service-form {
    width: 100%;
  }

  #terms-of-service-container .terms-of-service-form p {
    font-size: 13px;
  }

  #terms-of-service-container .terms-of-service-form li {
    list-style-type: disc;
    font-size: 12px;
    margin-left: 20px;
  }
}