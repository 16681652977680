.select-job-modal .card-list {
  margin: 0;
}

.action-modal .card{
  cursor: pointer;
}

.card.disabled {
  cursor: default;
}

.card.disabled h3.card-title {
  color: var(--card-sub-font-color);
}

.card.disabled .card-contents {
  background-color: #eeeeee;
}