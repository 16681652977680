.menu {
  display: flex;
  flex-direction: column;
  padding: 20px; 
  gap:10px;
}
.menu-item {
  padding: 15px 5px;
  color: var(--default-font-color);
  border-radius: 4px;
  cursor: pointer;
}
.active {
  font-weight: bold;
  color: var(--selected-font-color);
}

/* ------------------------------------------------ */
/* レスポンシブ対応 */
/* ------------------------------------------------ */
@media (max-width: 768px) {
  .menu{
    padding: 0;
    gap: 30px;
  }
  .menu-item{
    padding: 5px;
    font-size: 12px;
    text-align: left;
  }

/* ------------------------------------------------ */
/* レスポンシブ対応 - Height */
/* ------------------------------------------------ */
@media (max-height: 700px) {
  .menu{
    gap: 25px;
  }
}

@media (max-height: 620px) {
  .menu{
    gap: 15px;
  }
}

/* ------------------------------------------------ */
/* アニメーション */
/* ------------------------------------------------ */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}