#e-signature-list {
  background-color: var(--back-ground-color);
  display: flex;
  flex:1;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

/* ------------------------------------------------ */
/* 並び替え */
/* ------------------------------------------------ */
#e-signature-list .sorting-button {
  align-items: center;
  display: flex;
  min-width: 185px;
  position: relative;
}

/* ------------------------------------------------ */
/* カード */
/* ------------------------------------------------ */
#e-signature-master-card-contents {
  cursor: pointer;
}

/* ------------------------------------------------ */
/* レスポンシブ対応 */
/* ------------------------------------------------ */
@media (max-width: 1699px) {
  #e-signature-list .hide-option {
    display: none;
  }
}