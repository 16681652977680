.detail-modal {
  padding: 20px;
}

.detail-modal .paper-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.detail-modal.paper-list {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.detail-modal .paper-list th {
  background-color: var(--back-ground-color2);
  color: var(--table-header-font-color);
  padding: 12px;
  text-align: left;
  font-weight: normal;
}

.detail-modal .paper-list td {
  padding: 12px;
}

.detail-modal .paper-title {
  color: var(--main-font-color);
}

.detail-modal.confirm-date {
  color: var(--sub-font-color);
}