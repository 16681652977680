#paper-list {
  background-color: var(--back-ground-color);
  display: flex;
  flex:1;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
}

/* ------------------------------------------------ */
/* メインコンテンツ */
/* ------------------------------------------------ */
#paper-list h2 {
  min-height: 21px;
}

/* ------------------------------------------------ */
/* input */
/* ------------------------------------------------ */
#paper-list input#paper-list-date {
  background-color: transparent;
  cursor: auto;
  border: none;
  font-weight: 500;
}

/* ------------------------------------------------ */
/* レスポンシブ対応 */
/* ------------------------------------------------ */
@media (max-width: 1699px) {
  #paper-list .header-contents .hide-option {
    display: none;
  }
}

/* ------------------------------------------------ */
/* 左画面 */
/* ------------------------------------------------ */  
#paper-list .paper-list-contents {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 20px;
}

#paper-list .paper-list {
  display: grid ;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 30px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

#paper-list .paper-item {
  cursor: pointer;
  margin-bottom: 20px;
}

#paper-list .paper-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

#paper-list .paper-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#paper-list .paper-date {
  color: #666;
  font-size: 0.9em;
}

#paper-list .message-title {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  line-height: 1.5;
  max-width: 200px;
  text-align: left;
}

#paper-list .unread-badge-info {
  display: flex;
  align-items: center;
}

#paper-list .unread-badge {
  width: 10px;
  height: 10px;
  background-color: #007bff;
  border-radius: 50%;
}

/* ------------------------------------------------   */
/* 右画面 */
/* ------------------------------------------------ */
#paper-list .detail-content {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
}

#paper-list .detail-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#paper-list .detail-text {
  white-space: pre-wrap;
  line-height: 1.6;
  height: 50%;
  border: 2px solid var(--line-color);
  border-radius: 5px;
  padding: 10px;
}

#paper-list .detail-header {
  margin-bottom: 20px;
}

#paper-list .detail-meta{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#paper-list .detail-sender,
#paper-list .detail-date {
  color: #666;
}

#paper-list .detail-papers {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
}

.paper-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--line-color);
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
}

.paper-title {
  align-self: flex-start;
  display: block;
  font-size: 14px;
  height: auto;
  margin-bottom: 5px;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
