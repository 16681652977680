.modal-form#e-signature-form {
  padding: 0;
}

/* ------------------------------------------------ */
/* ドキュメントリスト */
/* ------------------------------------------------ */
.modal-form#e-signature-form .document-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  width: 90%;
}

.modal-form#e-signature-form .document-list-container {
  background-color: var(--card-back-ground-color);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom:10px;
  padding: 20px 10px;
}

.modal-form#e-signature-form .document-list-card {
  background-color: var(--card-back-ground-color);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.modal-form#e-signature-form .pdf-file-name {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  height: 30px;
}

.modal-form#e-signature-form .document-list-card .input-group {
  flex: 1;
}

.modal-form#e-signature-form .document-list-card .delete-button-wrapper {
  width: 30px;
  display: flex;
  justify-content: center;
}

.modal-form#e-signature-form .document-list-container .document-list-card:last-child {
  border-bottom: none;
}

.modal-form#e-signature-form .document-list-container .document-list-card:not(:last-child) {
  border-bottom: 1px solid var(--card-border-color);
}

/* ------------------------------------------------ */
/* ボタンコンテンツ */
/* ------------------------------------------------ */
.modal-form#e-signature-form .button-container {
  margin-top: 20px;
}

.modal-form#e-signature-form .add-new-item-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

/* ------------------------------------------------ */
/* 公開設定 */
/* ------------------------------------------------ */
.modal-form#e-signature-form .row-label {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.modal-form#e-signature-form .select-company-button{
  display: flex;
  padding: 20px 0 20px 10px;
}