.maintenance-mode {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  height: 100vh;
  width: 100vw;
}

.maintenance-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.maintenance-image img{
  width: 30%;
  height: auto;
}

.maintenance-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.maintenance-text h1 {
  font-size: 20px;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
}

.maintenance-sub-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:10px;
  padding: 50px 0;
}

.maintenance-sub-text p {
  font-size: 16px;
  font-weight: normal;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
}

.maintenance-sub-text p.maintenance-time {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
}

.maintenance-sub-text p.maintenance-small-sub-text {
  font-size: 12px;
  margin-top: 30px;
}

@media screen and (max-width: 1000px) {
  .maintenance-image img{
    width: 50%;
    height: auto;
  }
  .maintenance-text h1{
    font-size: 16px;
  }
  .maintenance-sub-text p{
    font-size: 14px;
  }
  .maintenance-sub-text p.maintenance-time {
    font-size: 16px;
    gap: 5px;
  }
}

@media screen and (max-width: 768px) {
  .maintenance-image img{
    width: 20%;
    height: auto;
  }
  .maintenance-mode{
    box-sizing: border-box;
    padding: 30px;
  }
  .maintenance-image img{
    width: 70%;
    height: auto;
  }
  .maintenance-text h1{
    font-size: 14px;
  }
  .maintenance-sub-text p{
    font-size: 12px;
    line-height: 1.5;
  }
  .maintenance-sub-text p.maintenance-time {
    font-size: 12px;
    gap: 5px;
  }
}