.vimeo-player-group{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.vimeo-player{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.vimeo-seekbar {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  width: 100%;
  margin-top: -10px;
}
.vimeo-seekbar.isAdmin {
  margin-top: -40px;
  width: 85%;
}
.vimeo-seekbar progress {
  width: 95%;
}
.vimeo-seekbar span {
  color: var(--white-font-color);
}
.vimeo-seekbar progress, .vimeo-seekbar input[type="range"] {
  width: 95%;
}
.vimeo-control-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: #ffffffcc;
  border-radius: 40px;
  width: 60%;
}
.vimeo-control-button{
  color: var(--blue-font-color);
}

.vimeo-player-container {
  background-color: #000;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.vimeo-player-iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: 177.78vh; /* 16:9のアスペクト比を維持 */
  max-height: 56.25vw; /* 16:9のアスペクト比を維持 */
}

.vimeo-player-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  padding-bottom: 20px;
}
