.status-filters {
  flex-grow: 0.5;
  display: flex;
  align-items: center;
  gap: 20px;
}
.status-filters input[type="radio"] {
  margin: 0 3px 0 5px;
  accent-color: var(--blue-font-color);
}
.status-filters label.status-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.status-filters label.disabled p {
  color: var(--disable-font-color);
}
/* ============================== */
/* レスポンシブ対応 */
/* ============================== */
@media (max-width: 480px) {
  .status-filters {
    gap: 15px;
  }
}