.modal-form#job-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
}

.modal-form#job-form .row-label {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.modal-form#job-form .select-company-button{
  display: flex;
  padding: 20px 0 20px 10px;
}

.modal-form#job-form .pdf-file-name {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 30px;
}

#job-form .vimeo-player-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 170px;
  padding-bottom: 20px;
}

@media (max-width:1200px){
  #job-form .vimeo-player-wrapper{
    padding-bottom: 20px;
    width: 75%;
  }
}

@media (max-width: 768px) {
  #job-form .vimeo-player-wrapper {
    width: 100%;
    height: 260px;
  }
}