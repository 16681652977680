#message-setting {
  background-color: var(--back-ground-color);
  display: flex;
  flex:1;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
}

/* ------------------------------------------------ */
/* カード */
/* ------------------------------------------------ */
#paper-setting-card-contents {
  cursor: pointer;
}

#paper-setting-card-contents .card-contents-left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10%;
}

#paper-setting-card-contents .card-titles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
#paper-setting-card-contents .card-titles h3{
  width: 250px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.detail-card-footer {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 10px; 
}

.detail-card-footer-item {
  color: var(--sub-font-color);
  font-size: 12px;
  text-align: left;
  text-wrap: nowrap;
  width: 100%;
}

#paper-setting-form{
 padding:0;
}

#paper-setting-card-contents .sender-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

#paper-setting-card-contents .sender-name-container span{
  color: var(--card-sub-font-color);
}

#paper-setting-card-contents .paper-icon-list {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

/* ------------------------------------------------ */
/* レスポンシブ対応 */
/* ------------------------------------------------ */
@media (max-width: 1599px) {
  #message-setting .header-contents .hide-option {
    display: none;
  }
}
@media (max-width: 540px) {
  #message-setting .sender-name-container {
    display: none;
  }
}

#message-setting .detail-papers {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

#message-setting .existing-papers {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

#message-setting .detail-paper {
  position: relative;
}

#message-setting .file-upload-area {
  width: 200px;
  min-height: 280px;
  border: 2px dashed var(--line-color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

#message-setting .file-upload-area.drag-active {
  border-color: var(--blue-font-color);
  background-color: rgba(0, 123, 255, 0.05);
}

#message-setting .upload-label {
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#message-setting .upload-icon {
  font-size: 40px;
  color: var(--blue-font-color);
  margin-bottom: 10px;
}

#message-setting .upload-text {
  color: var(--blue-font-color);
  font-weight: bold;
}

#message-setting .upload-subtext {
  font-size: 12px;
  color: var(--default-font-color);
  margin-top: 10px;
  font-weight: normal;
}

#message-setting .file-limit {
  font-size: 12px;
  color: var(--sub-font-color);
  margin-top: 10px;
  font-weight: normal;
}

#message-setting .delete-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background: white;
  border: none;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: 30px;
  height: 30px;
  font-size: 0;
}

#message-setting .delete-button:hover {
  transform: scale(1.1);
}