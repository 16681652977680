.staff-list-preview {
  width: 297mm; /* A4横サイズ */
  height: 210mm; /* A4縦サイズ */
  padding: 15mm;
  background-color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}

.staff-list-header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 5px;
}

.staff-list-header .title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.staff-list-header h1 {
  font-size: 22px;
  padding-bottom: 5px;
  text-align: center;
  margin: 0;
}

.staff-list-header .date{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.staff-list-header .date p {
  font-size: 12px;
}

/* テーブル */
.staff-list-table-header {
  display: flex;
  font-size: 14px;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.staff-list-table p {
  font-size: 14px;
  text-align: left;
}

.staff-list-table-header {
  display: flex;
  background-color: #d6e6ff;
  flex-direction: column;
  gap: 0;
  padding: 5px 0;
  margin-bottom: 5px;
  width: 100%;
  box-sizing: border-box;
}

.staff-list-table-body {
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
}

.staff-list-table-row {
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 5px 0;
  box-sizing: border-box;
  width: 100%;
}

.staff-list-table .first-row,
.staff-list-table .second-row,
.staff-list-table .third-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 5mm;
}

.staff-list-table-body > div:nth-child(even) {
  background-color: #ebf2fb;
}

.staff-list-table-body > div:nth-child(odd) {
  background-color: #ffffff;
}

.staff-list-table .staff-name,
.staff-list-table .staff-name-kana,
.staff-list-table .mail,
.staff-list-table .sex,
.staff-list-table .birthday,
.staff-list-table .group-name,
.staff-list-table .employment-date,
.staff-list-table .telphone,
.staff-list-table .address {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.staff-list-table .staff-name {
  width: 20%;
  padding: 1mm;
}
.staff-list-table .staff-name-kana {
  width: 30%;
  padding: 1mm;
}
.staff-list-table .mail {
  width: 30%;
  padding: 1mm;
}
.staff-list-table .sex {
  width: 6%;
  padding: 1mm;
}
.staff-list-table .birthday {
  width: 14%;
  padding: 1mm;
}

.staff-list-table .employment-date {
  width: 30%;
  padding: 1mm;
}
.staff-list-table .telphone {
  width: 20%;
  padding: 1mm;
}
.staff-list-table .address {
  width: 50%;
  padding: 1mm;
}

.staff-list-table .group-name {
  width: 20%;
  padding: 1mm;
}
.staff-list-table .remarks {
  width: 80%;
  padding: 1mm;
}