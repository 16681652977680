.modal-form#lc-setting-form{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
}

.modal-form#lc-setting-form .question-card-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-right: 10px;
}
/* 回答タイプ */
.modal-form#lc-setting-form .row-label {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}


/* 問題カード */
.modal-form#lc-setting-form .question-card {
  background-color: var(--card-back-ground-color);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-bottom: 1px solid var(--card-border-color);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  width: 100%;
}
/* Q */
.modal-form#lc-setting-form .question-card h4{
padding-bottom:10px;
}

/* 選択肢 */
.modal-form#lc-setting-form .choice-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}
/* 選択肢行 */
.modal-form#lc-setting-form .choice-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.modal-form#lc-setting-form .choice-row input[type="text"] {
  width: 100%;
}
/* ------------------------------------------------ */
/* ボタンコンテンツ */
/* ------------------------------------------------ */
.modal-form#lc-setting-form .button-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.modal-form#lc-setting-form .add-new-item-button {
  align-items: center;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
}

.modal-form#lc-setting-form .add-new-item-button p {
  color: var(--blue-font-color);
}

/* +ボタン */
.modal-form#lc-setting-form .add-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width:100%
}

/* ------------------------------------------------ */
/* レスポンシブ対応 */
/* ------------------------------------------------ */
@media (max-width: 1200px) {
  .modal-form#lc-setting-form .question-card-wrapper {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .modal-form#lc-setting-form .add-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width:100%
  }
}