label.toggle-switch-container {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 0;
  width: auto;
}
.toggle-switch-container.disabled p {
  color: var(--disable-font-color);
}
.toggle-switch-container p {
  width: auto;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.toggle-switch input {
  opacity: 0;
  width: 40px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  
}

.toggle-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.toggle-switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.toggle-switch input:checked + .slider {
  background-color: var(--primary-color);
}

.toggle-switch input:checked:disabled + .slider {
  background-color: #93c7ff;
}

.toggle-switch input:checked + .slider:before {
  transform: translateX(20px);
}



/* ============================== */
/* レスポンシブ対応 */
/* ============================== */
@media (max-width: 1200px) {
}
@media (max-width: 1024px) {
}

@media (max-width: 768px) {
}

@media (max-width: 480px) {
  .toggle-switch-container {
    width: 100%;
  }
}