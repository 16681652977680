#e-signature-list {
  background-color: var(--back-ground-color);
  display: flex;
  flex:1;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

/* ------------------------------------------------ */
/* テンプレートボタン周辺 */
/* ------------------------------------------------ */
#e-signature-list .document-info {
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 90%;
}

#e-signature-list .document-buttons {
  display: flex;
  gap: 10px;
}

#e-signature-list .document-buttons button {
  padding: 5px;
  background: none;
  border: none;
  cursor: pointer;
}

/* ------------------------------------------------ */
/* カード */
/* ------------------------------------------------ */
#e-signature-list-card-contents {
  cursor: pointer;
}

/* ------------------------------------------------ */
/* レスポンシブ対応 */
/* ------------------------------------------------ */
@media (max-width: 1599px) {
  #e-signature-list .hide-option {
    display: none;
  }
}