.mini-selected-view {
  background-color: var(--card-back-ground-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  overflow-y: scroll;
  max-height: 230px;
  width: 95%;
}

.mini-selected-view::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera用 */
}

.mini-selected-view p {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px 10px;
  border-radius: 10px;
  background-color: var(--content-back-ground-color);
}