.modal-overlay#select-groups-modal{
  /* 2階層目のモーダルのため、背景を透明にする */
  background: transparent;
}

.select-groups-modal .card .card-contents {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

@media (max-width: 1200px) {
  /* 2階層目のモーダルのため、シャドウを強めにする */
  .modal-overlay#select-groups-modal .modal-container{
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.09),
                 4px 10px 8px rgba(0, 0, 0, 0.09),
                 0 -8px 12px rgba(0, 0, 0, 0.09),
                 8px 20px 12px rgba(0, 0, 0, 0.09);
  }
}