.contract-start-message {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  background-color: #e3ffea;
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--green-font-color);
  font-weight: 600;
  margin-bottom: 10px;
  padding: 20px;  
  width: 100%;
  line-height: 1.4;
}
.contract-end-message {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  background-color: #ffe3e3;
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--red-font-color);
  font-weight: 600;
  margin-bottom: 10px;
  padding: 20px;  
  width: 100%;
}