/* 確認済みのバッジ */
.pdf-thumbnail-container {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f5f5f5;
  border: 1px solid var(--line-color);
}

.pdf-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.confirmed-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(76, 122, 175, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: opacity 0.2s ease;
}

.confirmed-overlay svg {
  width: 40px;
  height: 40px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
}

.confirmed-overlay-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.confirmed-overlay-text {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
}

.pdf-thumbnail-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.spinner-small {
  width: 24px;
  height: 24px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #4c7aaf;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
